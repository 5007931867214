// Tri.js

// Filtre de recherche
export const filterBySearchTerm = (data, searchTerm) => {
    if (!searchTerm) return data; // Si aucun terme de recherche n'est fourni, retourner toutes les données
    const terms = searchTerm.split(',').map(term => term.trim().toLowerCase());
    return data.filter(item => {
        return terms.some(term => 
            (item.serie && item.serie.toLowerCase().includes(term)) || 
            (item.resume && item.resume.toLowerCase().includes(term)) || 
            (item.messageText && item.messageText.toLowerCase().includes(term))
        );
    });
};

// Filtres par codeQ
export const filterByCodeQ = (data, codeQ, codeQ_tab) => {
    // Créer une liste des codes sélectionnés
    const selectedCodesQ = codeQ
        .filter((_, i) => codeQ_tab[i]) // Filtrer uniquement les codesQ où la case est cochée
        .map(item => item.code); // Obtenir les codes correspondants

    // Si aucun codeQ n'est sélectionné, retourner toutes les données
    if (selectedCodesQ.length === 0) return data;

    // Filtrer les données en fonction des codes sélectionnés
    console.log(selectedCodesQ);
    return data.filter(item => selectedCodesQ.includes(item.codeQ));
};

// Filtre par date (auj, dem, new)
export const filterByDate = (data, filter) => {
    if (filter === 'all' || !filter) return data; // Si le filtre date est 'all' ou absent, retourner toutes les données
    let date = new Date();
    return data.filter(item => {
        let dateNotam = new Date(item.debut);
        if (filter === 'auj') {
            return date.toDateString() === dateNotam.toDateString();
        } else if (filter === 'dem') {
            let tomorrow = new Date();
            tomorrow.setDate(date.getDate() + 1);
            return tomorrow.toDateString() === dateNotam.toDateString();
        } else if (filter === 'new') {
            return item.new === true;
        }
        return true; // Si aucun filtre date n'est appliqué, retourner toutes les données
    });
};

// Fonction combinée pour appliquer tous les filtres
export const applyCombinedFilters = (data, { searchTerm, codeQ, codeQ_tab, dateFilter }) => {
    let filteredData = data;

    // Appliquer les filtres dans l'ordre
    filteredData = filterBySearchTerm(filteredData, searchTerm); // Filtre par recherche
    filteredData = filterByCodeQ(filteredData, codeQ, codeQ_tab); // Filtre par codeQ
    filteredData = filterByDate(filteredData, dateFilter); // Filtre par date

    return filteredData; // Retourner les données filtrées
};
