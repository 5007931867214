import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid2, Chip, Switch, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaSpider } from 'react-icons/fa';
import Modale from './Modal';

function DataAccordion({ data, i }) {
    const [btiv, setBtiv] = useState(data[i].btiv || false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        setBtiv(data[i].btiv);
    }, [data, i]);

    const handleSpiderClick = (event, item) => {
        event.stopPropagation();
        setSelectedItem(item); // Enregistre l'élément sélectionné
        setIsModalOpen(true); // Ouvre la modale
    };

    const handleModaleClose = () => {
        setIsModalOpen(false);
    };

    const handleSwitchChange = (event) => {
        event.stopPropagation();
        const newBtivValue = event.target.checked;
        setBtiv(newBtivValue);
        
        let url = 'php/Traite.php';
        if (window.location.hostname === "localhost" || window.location.port === "3000")
            url = 'http://localhost/exoyes/Traite.php';
    
        const serieValue = data[i]?.serie;
        if (!serieValue) {
            console.error('Erreur: Le paramètre "serie" est manquant ou indéfini.');
            return;
        }
        
        data[i].btiv = newBtivValue;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ serie: serieValue }),
        })
        .then((response) => response.json())
        .then((result) => {
            console.log('Requête réussie :', result);
        })
        .catch((error) => {
            console.error('Erreur lors de l\'envoi de la requête :', error);
        });
    };
    
    const handleSwitchClick = (event) => {
        event.stopPropagation(); // Prevent accordion toggle on switch click
    };  
    
    const backgroundColor = btiv ? '#90EE90 ' : '#f5f5f5';
    const spiderColor = data[i].epeires === null || data[i].epeires === "" ? '#ffc900' : '#008c02';

    return (
        <> 
            {/* Modale */}
            {isModalOpen &&(
                <Modale
                    open={isModalOpen}
                    handleClose={handleModaleClose}
                    selectedItem={selectedItem}
                />
            )} 
            <Accordion key={i} square style={{ marginBottom: '16px', width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${i}-content`}
                    id={`panel${i}-header`}
                    style={{ backgroundColor: backgroundColor }}
                >
                    <Grid2 container spacing={2} alignItems="center">
                        <Grid2 item>
                            <Chip label={data[i].serie} size="small" color="primary" />
                        </Grid2>
                        <Grid2 item xs>
                            <Typography variant="h6" noWrap>
                                <strong>{data[i].resume.length > 30 ? `${data[i].resume.substring(0, 30)}...` : data[i].resume}</strong>
                            </Typography>
                        </Grid2>
                        {data[i].new && !data[i].btiv &&(
                            <Grid2 item sx={{ marginLeft: 'auto' }}>
                                <Chip label="New" size="small" color="success" />
                            </Grid2>
                        )}
                    </Grid2>
                    <Grid2 container spacing={2} sx={{ marginLeft: 'auto' }}>
                        {data[i].codeQ === 'MR' && (
                            <Grid2 item >
                                <IconButton onClick={(event) => handleSpiderClick(event, data[i])}>
                                    <FaSpider style={{ color: spiderColor }} />
                                </IconButton>
                            </Grid2>
                        )}
                        <Grid2 item>
                            <Switch
                                checked={btiv}
                                onClick={handleSwitchClick}
                                onChange={handleSwitchChange}
                                color="primary"
                                inputProps={{ 'aria-label': 'Traité Switch' }}
                            />
                        </Grid2>
                    </Grid2>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid2 container direction="column" spacing={2}>
                        <Grid2 item sx={{ marginRight: 'auto' }}>
                            <Typography variant="body1">
                                <strong>Résumé :</strong> {data[i].resume}
                            </Typography>
                        </Grid2>
                        <Grid2 item sx={{ marginRight: 'auto' }}>
                            <Typography variant="body1">
                                <strong>Date :</strong> {data[i].debut} - {data[i].fin}
                            </Typography>
                        </Grid2>
                        <Grid2 container spacing={2} alignItems="center" sx={{ marginRight: 'auto' }}>
                            <Grid2 item>
                                <Typography variant="body1">
                                    <strong>Centre :</strong> {data[i].centre}
                                </Typography>
                            </Grid2>
                            <Grid2 item>
                                <Typography variant="body1">
                                    <strong>Code Q :</strong> {data[i].codeQ}
                                </Typography>
                            </Grid2>
                            <Grid2 item>
                                <Typography variant="body1">
                                    <strong>Code État :</strong> {data[i].code_etat}
                                </Typography>
                            </Grid2>
                            {/* <Grid2 item>
                                <Typography variant="body1">
                                    <strong>Adresse :</strong> {data[i].addressees[0]}
                                </Typography>
                            </Grid2> */}
                        </Grid2>
                        {/* <Grid2 item sx={{ marginRight: 'auto' }}>
                            <Typography variant="body1">
                                <strong>Message complet :</strong> {data[i].messageText}
                            </Typography>
                        </Grid2> */}
                    </Grid2>
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export default DataAccordion;
