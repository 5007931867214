import React, { useState } from 'react';
import { Box, Drawer, AppBar, CssBaseline, Toolbar, List, Typography, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Ajout from './Ajout_code_q';
import Affiche from './Affichage_code_q';
import AjoutCentre from './Ajout_centre';
import AfficheCentre  from './Affichage_centre';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AddLocationIcon from '@mui/icons-material/AddLocation';

const drawerWidth = 240;

function Main() {
    const [selectedItem, setSelectedItem] = useState(''); // State to track selected item

    const handleListItemClick = (item) => {
      setSelectedItem(item);
    };
    
    // fonction pour afficher le contenu en fonction de l'élément sélectionné
    const renderContent = () => {
      switch (selectedItem) {
        case 'Code Q':
          return <Affiche />;
        case 'Ajout Code Q':
          return <Ajout />;
        case 'Centre':
          return <AfficheCentre  />;
        case 'Ajout Centre':
          return <AjoutCentre />;
        case 'Autres':
          return <Typography>Affichage du contenu pour Autres.</Typography>;
        default:
          return <Typography>Bienvenue dans l'interface d'administration.</Typography>;
      }
    };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor:'#e664e5',color:'#f5f5f5' }}>
        <Toolbar>
          <Typography variant="h3" noWrap component="div">
            ADMIN
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto',height:'100%', backgroundColor: '#4f9cce', color: '#f5f5f5' }}>
          <List>
            {[
              { text: 'Code Q', icon: <VisibilityIcon sx={{ color: '#f5f5f5' }}/> },
              { text: 'Ajout Code Q', icon: <AddCircleOutlineIcon sx={{ color: '#f5f5f5' }}/> },
              { text: 'Centre', icon: <BusinessCenterIcon sx={{ color: '#f5f5f5' }} /> },
              { text: 'Ajout Centre', icon: <AddLocationIcon sx={{ color: '#f5f5f5' }} /> },
              { text: 'Autres', icon: null  },
            ].map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton onClick={() => handleListItemClick(item.text)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Typography sx={{ marginBottom: 2 }}>
            {renderContent()}
        </Typography>
      </Box>
    </Box>
  );
}

export default Main;