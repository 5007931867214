import React from "react";
import { AppBar } from '@mui/material';
import { Toolbar } from '@mui/material';
import { Typography } from '@mui/material';

function Main(){

    return(
        <AppBar position="fixed" style={{ width: '100vw',backgroundColor:'#e664e5',color:'#f5f5f5' }}>
             <Toolbar style={{ gap: "10px"}}>                
                <Typography variant="h3" component="div">
                    ExoYes
                </Typography>
            </Toolbar>
        </AppBar>
    );

}

export default Main;