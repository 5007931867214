function parseDate(dateString) {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('-'); // Utilisez '-' pour séparer les éléments de la date

    // Créer une chaîne au format ISO
    const isoString = `${year}-${month}-${day}T${timePart}:00`; // Ajoutez :00 pour les secondes

    const dateObject = new Date(isoString);

    // Vérifiez si la date est valide
    if (isNaN(dateObject.getTime())) {
        throw new Error(`Invalid date: ${dateString}`);
    }

    return dateObject;
}

export default function Compteur({ data, setNouveauCount, setAujCount, setDemCount }) {
    // console.log('nope')
    // Vérifier si les données sont présentes
    if (!data || data.length === 0) {
        setNouveauCount(0);
        setAujCount(0);
        setDemCount(0);
        console.warn('Aucune donnée à traiter');
        return; // Sortir de la fonction si aucune donnée n'est disponible
    }
    

    const date_auj = new Date().toISOString().slice(0, 10);
    const date_dem = new Date();
    date_dem.setDate(date_dem.getDate() + 1);
    const date_dem_str = date_dem.toISOString().slice(0, 10);

    let nouveau = 0;
    let auj = 0;
    let dem = 0;

    for (let i = 0; i < data.length; i++) {
        // Ajout d'une vérification pour s'assurer que la propriété "debut" existe
        if (!data[i].debut) continue; // Passer à l'itération suivante si "debut" n'est pas défini
        let date_debut = parseDate(data[i].debut).toISOString().slice(0, 10);
        
        if (data[i].new) {
            // console.log('oui');
            if (data[i].btiv) continue; 
            else nouveau++;
        }

        if (date_debut === date_auj) {
            if (data[i].btiv) continue;
            else auj++;
        }

        if (date_debut === date_dem_str) {
            if (data[i].btiv) continue;
            else dem++;
        }
    }

    // console.log('Compteur mis à jour:', nouveau, auj, dem);
    // Mise à jour des compteurs
    setNouveauCount(nouveau);
    setAujCount(auj);
    setDemCount(dem);
}