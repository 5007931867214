import React, { useState, useEffect } from 'react';
import Nav from './Nav';
import Depli from './Depli';
import Drawer from './Drawer';
import Research from './Research';
import { useFetchData } from './Donnees';
import { applyCombinedFilters } from './Tri'; // Import the combined filters function

function Main() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [codeQ_tab, setCodeQ_Tab] = useState([]); // État pour stocker les états des cases à cocher
    const [timer, setTimer] = useState(null); // État pour le timer
    const [filterApplied, setFilterApplied] = useState(false); // État pour savoir si un filtre est appliqué
    const [selectedButton, setSelectedButton] = useState('all'); // État pour suivre le bouton sélectionné (date filter)
    const [codeQ, setCodeQ] = useState([]); // État pour stocker les données JSON

    // Variables pour les compteurs
    const [nouveauCount, setNouveauCount] = useState(0);
    const [aujCount, setAujCount] = useState(0);
    const [demCount, setDemCount] = useState(0);

    useEffect(() => {
        const fetchCodeQ = async () => {
            try {
                let url3 = 'php/CodeQ.php';
                if (window.location.hostname === "localhost" || window.location.port === "3000") {
                    url3 = 'http://localhost/exoyes/CodeQ.php';
                }
                const response = await fetch(url3);
                const data = await response.json();
                setCodeQ(data);
                setCodeQ_Tab(Array(data.length).fill(false)); // Initialiser les booléens des codes Q
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
            }
        };

        fetchCodeQ();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useFetchData(setData, setNouveauCount, setAujCount, setDemCount); // Récupérer les données et les mettre à jour périodiquement
    
    useEffect(() => {
        
        const combinedFilters = {
            searchTerm,
            codeQ: codeQ, // Utilisez ici les données originales qui contiennent les codes
            codeQ_tab,
            dateFilter: selectedButton,
        };
        
        const filtered = applyCombinedFilters(data, combinedFilters);
        setFilteredData(filtered);
        setFilterApplied(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, searchTerm, codeQ_tab, selectedButton]);
    
    useEffect(() => {
        if (filterApplied && filteredData.length > 0) {
            if (timer) clearTimeout(timer); // Réinitialise le timer s'il y en a déjà un
            const newTimer = setTimeout(() => {
                setFilteredData(data); // Réinitialiser à toutes les données
                setSearchTerm(''); // Réinitialiser la recherche
                setCodeQ_Tab(Array(data.length).fill(false)); // Désactiver toutes les cases à cocher
                setSelectedButton('all'); // Réinitialiser le bouton sélectionné (date filter)
                setTimer(null); // Remettre le timer à null après exécution
            }, 180000); // 3 minutes (180000 ms)
            setTimer(newTimer); // Enregistrer le nouveau minuteur
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredData]); // Redémarrer le minuteur lorsque les données filtrées changent    

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Nav />
            <div style={{ display: 'flex', flexGrow: 1 }}>
                <Drawer 
                    setFilteredData={setFilteredData} 
                    filteredData={filteredData} 
                    data={data} 
                    codeQ_tab={codeQ_tab} 
                    setCodeQ_Tab={setCodeQ_Tab} 
                    setFilterApplied={setFilterApplied} 
                    selectedButton={selectedButton} 
                    setSelectedButton={setSelectedButton}
                    codeQ={codeQ}
                    nouveauCount={nouveauCount}
                    aujCount={aujCount}
                    demCount={demCount}
                />
                <main style={{ flexGrow: 1, padding: '16px' }}>
                    <Research 
                        data={data} 
                        searchTerm={searchTerm} 
                        setSearchTerm={setSearchTerm} 
                        setFilteredData={setFilteredData} 
                        setFilterApplied={setFilterApplied}
                    />
                    <Depli data={filteredData} />
                </main>
            </div>
        </div>
    );
}

export default Main;
