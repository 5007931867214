import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Alert } from '@mui/material';
import axios from 'axios';

function Main() {
  // États pour stocker les valeurs du formulaire
  const [codeQ, setCodeQ] = useState('');
  const [descriptif, setDescriptif] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
        code_q: codeQ,
        desc: descriptif,
    };

    try {
        let url = 'http://localhost/exoyes/Ajout_code_q.php';
        if(window.location.hostname !== "localhost" || window.location.port !== "3000")
            url = 'php/Ajout_code_q.php';

      const response = await axios.post(url, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setMessage('Les données ont été envoyées avec succès !');
        setError(false);
      } else {
        setMessage('Une erreur est survenue lors de l\'envoi.');
        setError(true);
      }
    } catch (error) {
      setMessage('Erreur : ' + error.message);
      setError(true);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent: 'center',height: '100vh',padding: 2,}}>
      <Typography variant="h4" gutterBottom> Formulaire code Q </Typography>
      
      {/* Formulaire */}
      <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
        <TextField fullWidth label="Code Q" variant="outlined" margin="normal" value={codeQ} onChange={(e) => setCodeQ(e.target.value)} required/>
        <TextField fullWidth label="Descriptif" variant="outlined" margin="normal" value={descriptif} onChange={(e) => setDescriptif(e.target.value)} required/>

        <Button variant="contained" color="primary" type="submit" fullWidth sx={{ mt: 2 }}> Envoyer </Button>
      </form>

      {/* Message de succès ou d'erreur */}
      {message && (
        <Alert severity={error ? 'error' : 'success'} sx={{ mt: 2 }}> {message} </Alert>
      )}
    </Box>
  );
}

export default Main;
