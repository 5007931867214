import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import axios from 'axios';

function Main() {
  const [data, setData] = useState([]); // État pour stocker les données JSON
  const [editingId, setEditingId] = useState(null); // ID de la ligne en cours de modification
  const [open, setOpen] = useState(false); // Pour gérer la boîte de dialogue de suppression
  const [selectedId, setSelectedId] = useState(null); // ID sélectionné pour la suppression
  const [searchTerm, setSearchTerm] = useState(''); // État pour stocker le terme de recherche

  // Récupération des données depuis le serveur
  useEffect(() => {
    const fetchData = async () => {
      var url = 'php/Qdata.php';
      if (window.location.hostname === "localhost" || window.location.port === "3000")
        url = 'http://localhost/exoyes/Qdata.php';

      await fetch(url)
        .then((response) => response.json())
        .then((data) => setData(data))
        .catch((error) => console.error('Erreur lors de la récupération des données:', error));
    };

    fetchData();
  }, []);

  // Fonction pour gérer la suppression
  const handleDelete = async (id) => {
    try {
        let url = 'http://localhost/exoyes/delete_code_q.php';
        if(window.location.hostname !== "localhost" || window.location.port !== "3000")
            url = 'php/delete_code_q.php';

      const response = await axios.post(url, { id });
      if (response.status === 200) {
        setData(data.filter((item) => item.id !== id)); // Met à jour la liste en supprimant l'élément
        handleClose(); // Ferme la boîte de dialogue
      } else {
        console.error('Erreur lors de la suppression');
      }
    } catch (error) {
      console.error('Erreur lors de la requête de suppression:', error);
    }
  };

  // Fonction pour gérer la modification
  const handleSaveEdit = async (id, updatedCode, updatedDescriptif) => {
    try {
        let url = 'http://localhost/exoyes/modif_code_q.php';
        if(window.location.hostname !== "localhost" || window.location.port !== "3000")
            url = 'php/modif_code_q.php';

      const response = await axios.post(url, { id, code: updatedCode, descriptif: updatedDescriptif });
      if (response.status === 200) {
        setData(
          data.map((item) => (item.id === id ? { ...item, code: updatedCode, descriptif: updatedDescriptif } : item))
        );
        setEditingId(null); // Sort de la mode édition
      } else {
        console.error('Erreur lors de la modification');
      }
    } catch (error) {
      console.error('Erreur lors de la requête de modification:', error);
    }
  };

  // Ouverture de la boîte de dialogue de confirmation pour la suppression
  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  // Fermeture de la boîte de dialogue
  const handleClose = () => {
    setOpen(false);
  };

  // Fonction de filtrage des données selon le terme de recherche
  const filteredData = data.filter((row) =>
    row.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.descriptif.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      {/* Barre de recherche */}
      <TextField
        label="Rechercher"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ marginBottom: '20px',width: '50%' ,backgroundColor: '#f5f5f5'}}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', fontSize:'1.3em' }} >Code Q</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize:'1.3em' }} >Descriptif</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize:'1.3em' }} >Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row,index) => (
              <TableRow key={row.id} sx={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white' }}>
                <TableCell>
                  {editingId === row.id ? (
                    <TextField
                      value={row.code}
                      onChange={(e) =>
                        setData(
                          data.map((item) =>
                            item.id === row.id ? { ...item, code: e.target.value } : item
                          )
                        )
                      }
                    />
                  ) : (
                    row.code
                  )}
                </TableCell>
                <TableCell>
                  {editingId === row.id ? (
                    <TextField
                      value={row.descriptif}
                      onChange={(e) =>
                        setData(
                          data.map((item) =>
                            item.id === row.id ? { ...item, descriptif: e.target.value } : item
                          )
                        )
                      }
                    />
                  ) : (
                    row.descriptif
                  )}
                </TableCell>
                <TableCell>
                  {editingId === row.id ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleSaveEdit(row.id, row.code, row.descriptif)
                      }
                      sx={{ marginRight: 1 }}
                    >
                      Enregistrer
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setEditingId(row.id)}
                      sx={{ marginRight: 1 }}
                    >
                      Modifier
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleClickOpen(row.id)}
                  >
                    Supprimer
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Boîte de dialogue de confirmation */}
        <Dialog
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>{"Confirmer la suppression"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Es-tu sûr de vouloir supprimer cet élément ? Cette action est irréversible.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Annuler
            </Button>
            <Button
              onClick={() => handleDelete(selectedId)}
              color="secondary"
              autoFocus
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
    </div>
  );
}

export default Main;
