import Co from './Connexion/connexion';
import { useState } from 'react';
import Page from './Page_principale/Page';
import Admin from './Admin/Admin';

function Main() {
    const [connected, setConnected] = useState(0);

    let componentToDisplay = [];

    // Déterminer quel composant afficher en fonction de la valeur de connected
    if (connected === 0) {
        componentToDisplay = <Co setConnected={setConnected} />;
    } else if (connected === 1) {
        componentToDisplay = <Page />;
    }
    else if(connected === 2){
        componentToDisplay = <Admin />;
    }

    return (
        <div>
            {componentToDisplay}
        </div>
    );
}

export default Main;
