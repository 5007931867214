import React, { useState } from 'react';
import { TextField, Box, Button } from '@mui/material';
import { filterBySearchTerm } from './Tri';

function Research({ data, searchTerm, setSearchTerm, setFilteredData, setFilterApplied }) {
    const [query, setQuery] = useState(searchTerm); // Garde le terme de recherche localement

    // Fonction pour filtrer les données en fonction du terme de recherche
    const applyFilter = () => {
        const filteredData = filterBySearchTerm(data, query);
        setFilteredData(filteredData);
        setFilterApplied(true);
    };

    const handleSearchChange = (event) => {
        setQuery(event.target.value); // Met à jour localement le terme de recherche
    };

    const handleValidate = () => {
        setSearchTerm(query); // Met à jour le terme de recherche global et applique le filtre
        applyFilter(); // Applique le filtre lors du clic sur Valider
    };

    // Fonction pour détecter la touche "Enter" et valider la recherche
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleValidate();
        }
    };

    return (
        <Box 
            sx={{ 
                marginTop: '64px', 
                width: '50%', 
                marginLeft: 'auto', 
                marginRight: 'auto',
                display: 'flex',  // Disposition en ligne
                alignItems: 'center'  // Centrer verticalement
            }}
        >
            <TextField
                fullWidth
                label="Recherche"
                variant="outlined"
                value={query}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown} // Ajout de la détection du clavier
                placeholder="Rechercher dans série, résumé, et texte du message (séparés par des virgules pour une recherche multiple)"
                sx={{ marginRight: '16px' }} // Espacement entre le champ et le bouton
            />
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleValidate} // Valide lors du clic sur le bouton
            >
                Valider
            </Button>
        </Box>
    );
}

export default Research;
