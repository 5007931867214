import React from 'react';
import Nav from './Nav';

function Main(){

    let tab = [];

    tab.push(<Nav />)

    return(
        <div>
            {tab}
        </div>
    );
}

export default Main;