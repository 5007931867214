import React from "react";
import Typography from '@mui/material/Typography';
import Afficher from './Depli/Affichage';

function Main(props){
    const {data} = props;
    var tab = [];

    if (data.length > 0)
        for (var i = 0; i < data.length; i++)
            tab.push(<Afficher key={i} data={data} i={i} />);
    else
        tab.push(<Typography key="1" variant="h3">Aucun NOTAM</Typography>);
    
    return(
        <div style={{ textAlign: 'center',marginTop: "30px"}}>
            <div>
                {tab}
            </div>
        </div>
    );
}

export default Main;