import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Alert } from '@mui/material';
import axios from 'axios';

function Main() {
  const [centre, setCentre] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [lengthError, setLengthError] = useState(false); // État pour gérer l'erreur de longueur

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation pour s'assurer que le centre ne dépasse pas 4 caractères
    if (centre.length > 4) {
      setLengthError(true);
      return;
    }

    setLengthError(false); // Réinitialise l'erreur de longueur

    const data = {
      centre: centre,
    };

    try {
      let url = 'http://localhost/exoyes/Ajout_centre.php';
      if (window.location.hostname !== "localhost" || window.location.port !== "3000")
        url = 'php/Ajout_centre.php';

      const response = await axios.post(url, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setMessage('Les données ont été envoyées avec succès !');
        setError(false);
      } else {
        setMessage('Une erreur est survenue lors de l\'envoi.');
        setError(true);
      }
    } catch (error) {
      setMessage('Erreur : ' + error.message);
      setError(true);
    }
  };

  // Fonction pour gérer la modification du champ texte
  const handleInputChange = (e) => {
    const value = e.target.value.toUpperCase(); // Optionnel : convertir en majuscule
    setCentre(value);
    if (value.length > 4) {
      setLengthError(true);
    } else {
      setLengthError(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', padding: 2 }}>
      <Typography variant="h4" gutterBottom>Formulaire Centre</Typography>

      {/* Message en petit rouge */}
      <Typography variant="body2" color="error" sx={{ fontSize: '12px', mt: -1 }}>
        Attention : seulement 4 lettres max.
      </Typography>

      {/* Formulaire */}
      <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
        <TextField
          fullWidth
          label="centre"
          variant="outlined"
          margin="normal"
          value={centre}
          onChange={handleInputChange}
          error={lengthError} // Affiche l'erreur si longueur dépasse 4
          helperText={lengthError ? 'Ne peut pas dépasser 4 lettres.' : ''} // Affiche le texte d'erreur
          required
        />

        <Button variant="contained" color="primary" type="submit" fullWidth sx={{ mt: 2 }}>
          Envoyer
        </Button>
      </form>

      {/* Message de succès ou d'erreur */}
      {message && (
        <Alert severity={error ? 'error' : 'success'} sx={{ mt: 2 }}>
          {message}
        </Alert>
      )}
    </Box>
  );
}

export default Main;
