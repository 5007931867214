import { useEffect } from 'react';
import Compteur from './Compteur'; // Si c'est une exportation par défaut

export const useFetchData = (setData, setNouveauCount, setAujCount, setDemCount ) => {
    useEffect(() => {
        // Fonction sleep pour attendre un certain temps
        // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        const fetchData = async () => {
            let url = 'php/TestAPIRobin/Recup_data.php';
            if (window.location.hostname === "localhost" || window.location.port === "3000") {
                url = 'http://localhost/exoyes/TestAPIRobin/Recup_data.php';
            }

            await fetch(url)
                .then((response) => response.json())
                .catch((error) => console.error('Erreur lors de la récupération des données:', error));
        };

        const fetchjson = async () => {
            try {
                let url2 = 'php/data.php';
                if (window.location.hostname === "localhost" || window.location.port === "3000") {
                    url2 = 'http://localhost/exoyes/data.php';
                }
                const response = await fetch(url2);
                const data = await response.json();
                setData(data);
                Compteur({data, setNouveauCount, setAujCount, setDemCount});
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
            }
        };

        const fetchAllData = async () => {
            await fetchData();  // Appel fetchData
            // await sleep(500);  // Pause de 0.5 seconde
            await fetchjson();  // Appel fetchjson
        };

        fetchAllData();

        // Définir les intervalles pour les mises à jour périodiques
        const intervalId = setInterval(fetchData, 10 * 60 * 1000);  // 10 minute
        const intervalId2 = setInterval(fetchjson, 10 * 60 * 1000); // 10 minutes

        return () => {
            clearInterval(intervalId);
            clearInterval(intervalId2);
        };
    

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setData]); // Ajoutez vos dépendances ici
};
