import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Grid2 } from '@mui/material';

const Modale = ({ open, handleClose, selectedItem }) => {
    const [desc, setDesc] = useState(''); // description sur epeires
    const [Aero, setAero] = useState(''); // aerodrome
    const [detail, setDetail] = useState(selectedItem.resume); // detail
    const [datedebut, setDatedebut] = useState(selectedItem.debut); // date de début
    const [datefin, setDatefin] = useState(selectedItem.fin); // date de fin
    const [errorMessage, setErrorMessage] = useState(''); // message d'erreur

    const handleInputChange = (event) => {
        setDesc(event.target.value);
    };

    const validateDate = (dateString) => {
        // Regex pour vérifier le format dd-mm-yyyy HH:ss
        const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4}) ([01][0-9]|2[0-3]):([0-5][0-9])$/;
        return regex.test(dateString);
    };

    const onSubmit = () => {
        if (!desc) {
            setErrorMessage('Le champ "Description" est obligatoire.');
            return;
        }
        if (!validateDate(datedebut) || !validateDate(datefin)) {
            setErrorMessage('Veuillez entrer une date valide au format dd-mm-yyyy HH:ss.');
            return;
        }
        handleSubmit({ desc, Aero, detail, datedebut, datefin });
        setDesc('');
        setAero('');
        setDetail('');
        setDatedebut('');
        setDatefin('');
        handleClose();
    };

    const handleSubmit = (data) => {
        const envoi = {
            description: data.desc,
            aerodrome: data.Aero,
            detail: data.detail,
            datedebut: data.datedebut,
            datefin: data.datefin,
            id: selectedItem.serie
        };

        console.log('Données à envoyer :', envoi);
        let url = 'php/TestAPIRobin/Modal.php';
        if (window.location.hostname === "localhost" || window.location.port === "3000") {
            url = 'http://localhost/exoyes/TestAPIRobin/Modal.php';
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(envoi),
        })
            .then((response) => response.json())
            .then((result) => {
                console.log('Requête réussie :', result);
            })
            .catch((error) => {
                console.error('Erreur lors de l\'envoi de la requête :', error);
            });
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                <Typography variant="h6" component="h2">
                    Données à envoyer vers Epeires
                </Typography>
                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    value={desc}
                    onChange={handleInputChange}
                    sx={{ mt: 2, mb: 2 }}
                    required
                />
                <TextField
                    label="Aérodrome"
                    variant="outlined"
                    fullWidth
                    value={Aero}
                    onChange={(event) => setAero(event.target.value)}
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Détail"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2} // Ajuste la hauteur initiale
                    value={detail}
                    onChange={(event) => setDetail(event.target.value)}
                    sx={{ mb: 2 }}
                />
                <Grid2 container width='100%' spacing={2}>
                    <Grid2 item xs={6}>
                        <TextField
                            label="Date de début"
                            variant="outlined"
                            fullWidth
                            value={datedebut}
                            onChange={(event) => setDatedebut(event.target.value)}
                            sx={{ mb: 2 }}
                        />
                    </Grid2>
                    <Grid2 item xs={6}>
                        <TextField
                            label="Date de fin"
                            variant="outlined"
                            fullWidth
                            value={datefin}
                            onChange={(event) => setDatefin(event.target.value)}
                            sx={{ mb: 2 }}
                        />
                    </Grid2>
                </Grid2>
                <Grid2 container justifyContent="center" sx={{ mt: 2 }}>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        Envoyer
                    </Button>
                </Grid2>
            </Box>
        </Modal>
    );
};

export default Modale;
