import React, { useState } from 'react';
import { Accordion, AccordionDetails, Box, Drawer, List, ListItem, ListItemText, Typography, AccordionSummary, Checkbox, Tooltip, Button, Chip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { filterByDate } from './Tri';

function Main(props) {
    const { setFilteredData, data, setCodeQ_Tab, codeQ_tab, setFilterApplied, selectedButton, setSelectedButton, codeQ, nouveauCount, aujCount, demCount} = props;

    // États pour savoir si les accordéons sont ouverts
    const [expanded, setExpanded] = useState({ panel1: false });

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(prev => ({ ...prev, [panel]: isExpanded }));
    };

    // Fonction pour gérer les changements des cases à cocher et appliquer le filtre
    const handleCheckboxChange = (index) => {
        const updatedTab = [...codeQ_tab];
        updatedTab[index] = !updatedTab[index];
        setCodeQ_Tab(updatedTab);
    
        // const filtered = filterByCodeQ(data, codeQ, updatedTab);
        // setFilteredData(filtered);
        setFilterApplied(true);
    };

    const handleButtontri = (nom) => {
        setSelectedButton(nom);
        const filtered = filterByDate(data, nom);
        setFilteredData(filtered);
        setFilterApplied(true);
    };

    return (
        <Box sx={{ display: 'flex', marginTop: '64px', backgroundColor: '#4f9cce', color: '#f5f5f5', height: 'calc(100vh - 64px)' }}>
            <Drawer variant="permanent" anchor="left" sx={{ width: 240 }} PaperProps={{ style: { width: 240, position: 'fixed', top: 0, marginTop: '64px' } }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#4f9cce', color: '#f5f5f5' }}>
                    <Accordion expanded={expanded.panel1} onChange={handleAccordionChange('panel1')} sx={{ backgroundColor: '#039dfc', margin: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel1-content`} id={`panel1-header`}>
                            <Typography variant="h6" sx={{ color: '#f5f5f5' }}>Code Q</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ maxHeight: '50vh', overflow: 'auto' }}>
                            <List sx={{ color: '#f5f5f5' }}>
                                {codeQ.map((item, index) => (
                                    <ListItem key={index} button onClick={() => handleCheckboxChange(index)}>
                                        <Checkbox checked={codeQ_tab[index] || false} onChange={(e) => e.stopPropagation()} sx={{ color: '#f5f5f5' }} />
                                        <Tooltip title={<span style={{ fontSize: '1.2rem' }}>{item.descriptif || "Descriptif not available"}</span>} placement="left">
                                            <ListItemText sx={{ color: '#f5f5f5', fontSize: '1.5rem' }} primary={item.code || "Code not available"} />
                                        </Tooltip>
                                    </ListItem> 
                                ))}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                    {/* Partie tri de Notam via date */}
                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: '10px', marginTop: "20px" }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: selectedButton === 'all' ? '#DAF7A6' : '#F28CB1',
                                color: '#333',
                                padding: '10px 26px',
                                textAlign: 'center',
                                textDecoration: 'none',
                                display: 'inline-block',
                                fontSize: '16px',
                                borderRadius: '8px',
                                boxShadow: '0px 9px 20px -5px rgba(0,0,0,0.53)',
                                margin: '4px 2px'
                            }}
                            onClick={() => handleButtontri('all')}
                        >
                            <strong>Tout</strong>
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: selectedButton === 'auj' ? '#DAF7A6' : '#F28CB1',
                                color: '#333',
                                padding: '10px 26px',
                                textAlign: 'center',
                                textDecoration: 'none',
                                // display: 'inline-block',
                                fontSize: '16px',
                                borderRadius: '8px',
                                boxShadow: '0px 9px 20px -5px rgba(0,0,0,0.53)',
                                margin: '4px 2px',
                                display: 'flex',  
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                            onClick={() => handleButtontri('auj')}
                        >
                            <span style={{ display: 'block', width: '100%', textAlign: 'center' }}>
                                <strong>Aujourd'hui</strong>
                            </span>
                            <Chip label={aujCount} sx={{color: '#FFFFFF', backgroundColor: '#333',marginLeft: 'auto',fontWeight: 'bold'}} />
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: selectedButton === 'dem' ? '#DAF7A6' : '#F28CB1',
                                color: '#333',
                                padding: '10px 26px',
                                textAlign: 'center',
                                textDecoration: 'none',
                                // display: 'inline-block',
                                fontSize: '16px',
                                borderRadius: '8px',
                                boxShadow: '0px 9px 20px -5px rgba(0,0,0,0.53)',
                                margin: '4px 2px',
                                display: 'flex',  
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                            onClick={() => handleButtontri('dem')}
                        >
                            <span style={{ display: 'block', width: '100%', textAlign: 'center' }}>
                                <strong>Demain</strong>
                            </span>
                            <Chip label={demCount} sx={{color: '#FFFFFF', backgroundColor: '#333',marginLeft: 'auto',fontWeight: 'bold'}} />
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: selectedButton === 'new' ? '#DAF7A6' : '#F28CB1',
                                color: '#333',
                                padding: '10px 26px',
                                textAlign: 'center',
                                textDecoration: 'none',
                                // display: 'inline-block',
                                fontSize: '16px',
                                borderRadius: '8px',
                                boxShadow: '0px 9px 20px -5px rgba(0,0,0,0.53)',
                                margin: '4px 2px',
                                display: 'flex',  
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                            onClick={() => handleButtontri('new')}
                        >
                            <span style={{ display: 'block', width: '100%', textAlign: 'center' }}>
                                <strong>New</strong>
                            </span>
                            <Chip label={nouveauCount} sx={{color: '#FFFFFF', backgroundColor: '#333',marginLeft: 'auto',fontWeight: 'bold'}} />
                        </Button>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
}

export default Main;
